import React from "react"
import ContentWithImage from "../../components/contentWithImage"
import Layout from "../../components/layout"
import { sports } from "../../data/co-curricular"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
function Sports(props) {
  return (
    <div>
      <Layout>
        <SEO
          title="Sports"
          description="IITR boasts an Olympic size swimming pool that is open for 7+ months, i.e., from July to October and then March to May. This open pool is situated near the main building. Whether you are a complete beginner or an experienced swimmer, there are coaches that can help you touch that finish line faster. The insti team also has expert coaches and separate timings to train better. So come here and show off that butterfly stroke of yours or learn to play water polo."
          image={require("../../images/sports and interiit/Football and Hockey Ground.jpeg")}
        />
        <h2 className="dark-color-head">Sports Facilities</h2>
        {sports.map(sport => (
          <div>
            <ContentWithImage
              {...sport}
              fluid={
                props.data.allFile.edges.find(
                  edge => edge.node.name === sport.title
                ).node.childImageSharp.fluid
              }
            />
          </div>
        ))}
      </Layout>
    </div>
  )
}

export default Sports

export const query = graphql`
  {
    allFile(filter: { relativeDirectory: { eq: "sports and interiit" } }) {
      totalCount
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
  }
`
